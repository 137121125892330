<template>
  <section class="containerResultsSalesForce">
    <resultsPage :config="configuration"></resultsPage>
  </section>
</template>

<script>
import { ref } from 'vue'
import store from '../../store/index'
import { useStore } from 'vuex'
import resultsPage from '@/components/resultsPage/resultsPage'
import configurationFile from './resultsSalesForce.json'

export default {
  name: 'salesForce',
  components: {
    resultsPage
  },
  beforeRouteEnter () {
    store.commit({
      type: 'CHANGE_DEFAULT_FLOW',
      defaultSource: 'salesForce'
    })
  },
  setup (props) {
    const store = useStore()
    const configuration = ref(Object.assign(configurationFile.config))

    return {
      store,
      configuration
    }
  }
}
</script>
